export const Icon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_593_9101)">
			<path
				d="M11.9893 1.96777C13.0123 1.96777 13.9593 2.48877 14.5013 3.32677L14.6043 3.49877L21.7043 15.7488L21.7663 15.8748C21.9437 16.3134 22.0148 16.7878 21.9738 17.2592C21.9328 17.7306 21.7809 18.1856 21.5305 18.5871C21.2801 18.9885 20.9383 19.3251 20.5329 19.5692C20.1276 19.8133 19.6703 19.9581 19.1983 19.9918L18.9993 19.9998H4.99934L4.95034 19.9968L4.83834 19.9988C4.39294 19.9759 3.95826 19.8539 3.56592 19.6418C3.17359 19.4297 2.83348 19.1329 2.57034 18.7728L2.46134 18.6128C2.22318 18.2386 2.07096 17.8163 2.01562 17.3762C1.96029 16.9361 2.00323 16.4893 2.14134 16.0678L2.21334 15.8738L2.27334 15.7488L9.36534 3.51577C9.62473 3.0468 10.005 2.65588 10.4666 2.38364C10.9283 2.11141 11.4544 1.96781 11.9903 1.96777H11.9893ZM12.0093 13.9998L11.8823 14.0068C11.6393 14.0357 11.4153 14.1527 11.2528 14.3357C11.0902 14.5187 11.0005 14.755 11.0005 14.9998C11.0005 15.2445 11.0902 15.4808 11.2528 15.6638C11.4153 15.8468 11.6393 15.9639 11.8823 15.9928L11.9993 15.9998L12.1263 15.9928C12.3694 15.9639 12.5934 15.8468 12.7559 15.6638C12.9185 15.4808 13.0082 15.2445 13.0082 14.9998C13.0082 14.755 12.9185 14.5187 12.7559 14.3357C12.5934 14.1527 12.3694 14.0357 12.1263 14.0068L12.0093 13.9998ZM11.9993 7.99977C11.7544 7.99981 11.518 8.08973 11.335 8.25249C11.1519 8.41525 11.035 8.63952 11.0063 8.88277L10.9993 8.99977V10.9998L11.0063 11.1168C11.0352 11.3598 11.1523 11.5838 11.3353 11.7464C11.5183 11.9089 11.7546 11.9987 11.9993 11.9987C12.2441 11.9987 12.4804 11.9089 12.6634 11.7464C12.8464 11.5838 12.9634 11.3598 12.9923 11.1168L12.9993 10.9998V8.99977L12.9923 8.88277C12.9637 8.63952 12.8467 8.41525 12.6637 8.25249C12.4807 8.08973 12.2443 7.99981 11.9993 7.99977Z"
				fill="#DE9B45"
			/>
		</g>
		<defs>
			<clipPath id="clip0_593_9101">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
