<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5596 2.75326C17.6344 2.83035 17.6914 2.91833 17.7305 3.012C17.7719 3.11089 17.7948 3.21944 17.7948 3.33333V8.33333C17.7948 8.79357 17.4214 9.16667 16.9609 9.16667C16.5003 9.16667 16.1269 8.79357 16.1269 8.33333V5.34518L10.8791 10.5893C10.5534 10.9147 10.0254 10.9147 9.69975 10.5893C9.37408 10.2638 9.37408 9.73618 9.69975 9.41074L14.9476 4.16667H11.9573C11.4967 4.16667 11.1234 3.79357 11.1234 3.33333C11.1234 2.8731 11.4967 2.5 11.9573 2.5H16.9607H16.9609C17.1865 2.5 17.3912 2.58956 17.5414 2.73504C17.5475 2.74102 17.5536 2.74709 17.5596 2.75326ZM5.28585 5C4.62233 5 3.98599 5.26339 3.51682 5.73223C3.04764 6.20107 2.78406 6.83696 2.78406 7.5V15C2.78406 15.663 3.04764 16.2989 3.51682 16.7678C3.98599 17.2366 4.62233 17.5 5.28585 17.5H12.7912C13.4547 17.5 14.0911 17.2366 14.5603 16.7678C15.0294 16.2989 15.293 15.663 15.293 15V11.6667C15.293 11.2064 14.9196 10.8333 14.4591 10.8333C13.9985 10.8333 13.6251 11.2064 13.6251 11.6667V15C13.6251 15.221 13.5373 15.433 13.3809 15.5893C13.2245 15.7455 13.0124 15.8333 12.7912 15.8333H5.28585C5.06468 15.8333 4.85256 15.7455 4.69617 15.5893C4.53978 15.433 4.45192 15.221 4.45192 15V7.5C4.45192 7.27899 4.53978 7.06702 4.69617 6.91074C4.85256 6.75446 5.06468 6.66667 5.28585 6.66667H8.62157C9.08214 6.66667 9.4555 6.29357 9.4555 5.83333C9.4555 5.3731 9.08214 5 8.62157 5H5.28585Z"
    />
  </svg>
</template>
