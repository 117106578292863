import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import checkAuth from "@/utils/checkAuth";
import HomeView from "../views/SignInView.vue";
import i18n from "@/i18n";
import { useProfilData } from "@/stores/profilData";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/sign-in",
		name: "sign-in",
		component: HomeView,
		meta: {
			title: i18n.global.t("routes.sign-in.title"),
			showLogo: true,
		},
	},
	{
		path: "/sign-up",
		name: "sign-up",
		component: () => import("../views/SignUpView.vue"),
		meta: {
			title: i18n.global.t("routes.sign-up.title"),
			showLogo: true,
		},
	},
	{
		path: "/forgot-password",
		name: "forgot-password",
		component: () => import("../views/ForgotPasswordView.vue"),
		meta: {
			title: i18n.global.t("routes.forgot-password.title"),
			showLogo: true,
		},
	},
	{
		path: "/reset-password",
		name: "reset-password",
		component: () => import("../views/ResetPasswordView.vue"),
		meta: {
			title: i18n.global.t("routes.reset-password.title"),
			showLogo: true,
		},
	},
	{
		path: "/profile",
		name: "profile",
		component: () => import("../views/ProfileView.vue"),
		meta: {
			title: i18n.global.t("routes.profile.title"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/settings",
		name: "settings",
		component: () => import("../views/SettingsView.vue"),
		meta: {
			title: i18n.global.t("routes.settings.title"),
			subTitle: i18n.global.t("routes.settings.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/settings/private-informations",
		name: "private-informations",
		component: () => import("../views/PrivateInformationsView.vue"),
		meta: {
			title: i18n.global.t("routes.private-informations.title"),
			subTitle: i18n.global.t("routes.private-informations.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/settings/public-informations",
		name: "public-informations",
		component: () => import("../views/PublicInformationsView.vue"),
		meta: {
			title: i18n.global.t("routes.public-informations.title"),
			subTitle: i18n.global.t("routes.public-informations.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/analytics",
		name: "analytics",
		component: () => import("../views/Analytics/AnalyticsView.vue"),
		redirect: { name: "analytics-overview" },
		meta: {
			title: i18n.global.t("routes.analytics.title"),
			subTitle: i18n.global.t("routes.analytics.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
		children: [
			{
				path: "overview",
				name: "analytics-overview",
				component: () =>
					import("../views/Analytics/AnalyticsOverviewView.vue"),
				meta: {
					title: i18n.global.t("routes.analytics-overview.title"),
					subTitle: i18n.global.t(
						"routes.analytics-overview.subTitle"
					),
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "buildings",
				name: "analytics-buildings",
				component: () =>
					import("../views/Analytics/AnalyticsBuildingsView.vue"),
				meta: {
					title: i18n.global.t("routes.analytics-buildings.title"),
					subTitle: i18n.global.t(
						"routes.analytics-buildings.subTitle"
					),
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "buildings/:id",
				name: "analytics-building",
				component: () =>
					import(
						"../views/Analytics/AnalyticsSingleBuildingView.vue"
					),
				meta: {
					title: i18n.global.t("routes.analytics-building.title"),
					subTitle: "Building name:",
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "products",
				name: "analytics-products",
				redirect: { name: "analytics-overview" },
				meta: {
					title: i18n.global.t("routes.analytics-products.title"),
					subTitle: i18n.global.t(
						"routes.analytics-products.subTitle"
					),
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "products/:id",
				name: "analytics-product",
				component: () =>
					import("../views/Analytics/AnalyticsSingleProductView.vue"),
				meta: {
					title: i18n.global.t("routes.analytics-product.title"),
					subTitle: "Product name:",
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "contests",
				name: "analytics-contests",
				component: () =>
					import("../views/Analytics/AnalyticsContestsView.vue"),
				meta: {
					title: i18n.global.t("routes.analytics-contests.title"),
					subTitle: i18n.global.t(
						"routes.analytics-contests.subTitle"
					),
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "contests/:id",
				name: "analytics-contest",
				component: () =>
					import("../views/Analytics/AnalyticsSingleContestView.vue"),
				meta: {
					title: i18n.global.t("routes.analytics-contest.title"),
					subTitle: "Contest name:",
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "ads",
				name: "analytics-ads",
				component: () =>
					import("../views/Analytics/AnalyticsAdsView.vue"),
				meta: {
					title: i18n.global.t("routes.analytics-ads.title"),
					subTitle: i18n.global.t("routes.analytics-ads.subTitle"),
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
			{
				path: "ads/:id",
				name: "analytics-ad",
				component: () =>
					import("../views/Analytics/AnalyticsSingleAdView.vue"),
				meta: {
					title: i18n.global.t("routes.analytics-ad.title"),
					subTitle: "Ad name:",
					showSideBar: true,
					showTopBar: true,
					showFooter: true,
					showLogo: true,
				},
			},
		],
	},
	{
		path: "/buildings",
		name: "buildings",
		component: () => import("../views/BuildingsView.vue"),
		meta: {
			title: i18n.global.t("routes.buildings.title"),
			subTitle: i18n.global.t("routes.buildings.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/buildings/create",
		name: "create-building",
		component: () => import("../views/CreateBuildingView.vue"),
		meta: {
			title: i18n.global.t("routes.create-building.title"),
			subTitle: i18n.global.t("routes.create-building.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/buildings/:id",
		name: "edit-building",
		component: () => import("../views/EditBuildingView.vue"),
		meta: {
			title: i18n.global.t("routes.edit-building.title"),
			subTitle: i18n.global.t("routes.edit-building.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/products",
		name: "products",
		component: () => import("../views/ProductsView.vue"),
		meta: {
			title: i18n.global.t("routes.products.title"),
			subTitle: i18n.global.t("routes.products.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/products/create",
		name: "create-product",
		component: () => import("../views/CreateProductView.vue"),
		meta: {
			title: i18n.global.t("routes.create-product.title"),
			subTitle:
				"You can sell your product on Oona or on your website. It's up to you.",
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/products/:id",
		name: "edit-product",
		component: () => import("../views/EditProductView.vue"),
		meta: {
			title: i18n.global.t("routes.edit-product.title"),
			subTitle: "Edit your product informations",
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/articles",
		name: "articles",
		component: () => import("../views/ArticlesView.vue"),
		meta: {
			title: i18n.global.t("routes.articles.title"),
			subTitle: i18n.global.t("routes.articles.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/articles/create",
		name: "create-article",
		component: () => import("../views/CreateArticleView.vue"),
		meta: {
			title: i18n.global.t("routes.create-article.title"),
			subtitle: i18n.global.t("routes.create-article.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/articles/:id",
		name: "edit-article",
		component: () => import("../views/EditArticleView.vue"),
		meta: {
			title: i18n.global.t("routes.edit-article.title"),
			subtitle: i18n.global.t("routes.edit-article.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/competitions",
		name: "competitions",
		component: () => import("../views/CompetitionsView.vue"),
		meta: {
			title: i18n.global.t("routes.competitions.title"),
			subTitle: i18n.global.t("routes.competitions.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/competitions/create",
		name: "create-competition",
		component: () => import("../views/CreateCompetitionView.vue"),
		meta: {
			title: i18n.global.t("routes.create-competition.title"),
			subTitle: i18n.global.t("routes.create-competition.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/competitions/:id",
		name: "edit-competition",
		component: () => import("../views/EditCompetitionView.vue"),
		meta: {
			title: i18n.global.t("routes.edit-competition.title"),
			subTitle: i18n.global.t("routes.edit-competition.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/ads",
		name: "ads",
		component: () => import("../views/AdsView.vue"),
		meta: {
			title: i18n.global.t("routes.ads.title"),
			subTitle: i18n.global.t("routes.ads.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/ads/create",
		name: "create-ad",
		component: () => import("../views/CreateAdView.vue"),
		meta: {
			title: i18n.global.t("routes.create-ad.title"),
			subTitle: i18n.global.t("routes.create-ad.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/refills",
		name: "refills",
		component: () => import("../views/RefillsView.vue"),
		meta: {
			title: i18n.global.t("routes.refills.title"),
			subTitle: i18n.global.t("routes.refills.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/subscriptions",
		name: "subscriptions",
		component: () => import("../views/SubscriptionsView.vue"),
		meta: {
			title: i18n.global.t("routes.subscriptions.title"),
			subTitle: i18n.global.t("routes.subscriptions.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/confirm-email",
		name: "confirm-email",
		component: () => import("../views/ConfirmEmailView.vue"),
		meta: {
			title: i18n.global.t("routes.confirm-email.title"),
			subTitle: i18n.global.t("routes.confirm-email.subTitle"),
			showSideBar: false,
			showTopBar: false,
			showFooter: false,
			showLogo: true,
		},
	},
	{
		path: "/help",
		name: "help",
		component: () => import("../views/NeedHelpView.vue"),
		meta: {
			title: i18n.global.t("routes.confirm-email.title"),
			subTitle: i18n.global.t("routes.confirm-email.subTitle"),
			showSideBar: true,
			showTopBar: true,
			showFooter: true,
			showLogo: true,
		},
	},
	{
		path: "/:pathMatch(.*)*",
		name: "not-found",
		component: () => import("../views/NotFoundView.vue"),
		meta: {
			title: i18n.global.t("routes.not-found.title"),
			subTitle: i18n.global.t("routes.not-found.subTitle"),
			showLogo: true,
		},
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	async scrollBehavior(to, from, savedPosition) {
		await router.isReady();

		return new Promise((resolve) => {
			setTimeout(() => {
				if (savedPosition) {
					resolve(savedPosition);
				} else {
					resolve({ top: 0 });
				}
			}, 150); // 150ms delay to cover the time of the transition
		});
	},
});

// Redirect to login page if not logged in and trying to access a restricted page
router.beforeEach(async (to, from, next) => {
	const publicPages = [
		"/sign-in",
		"/sign-up",
		"/forgot-password",
		"/confirm-email",
		"/reset-password",
	];
	const authRequired = !publicPages.includes(to.path);

	const premiumPages = [
		"/products",
		"/products/create",
		"/articles",
		"/articles/create",
	];

	const profilData = useProfilData();

	// if trying to access a premium page and user is not premium, redirect to subscription page
	if (premiumPages.includes(to.path) && profilData.getSubscription === "") {
		return next("/subscriptions");
	}

	// Set page title
	const pageTitle =
		typeof to.meta.title === "string" ? to.meta.title : "Oona Dashboard";
	document.title = "Oona Dashboard - " + pageTitle;

	// if trying to access logout, always redirect to sign-in
	if (to.path === "/logout") {
		// clear local storage
		localStorage.clear();
		// redirect to sign-in
		return next("/sign-in");
	}

	// if local storage contains firstLaucnh, redirect to onboarding
	if (to.path !== "/sign-up" && localStorage.getItem("firstLaunch")) {
		return next({
			path: "/sign-up",
			state: {
				onboarding: true,
			},
		});
	}

	if (to.path === "/") {
		return next("/sign-in");
	}

	if (to.path === "/sign-in" && (await checkAuth())) {
		return next("/profile");
	}

	// if trying to access not-found page, allow access
	if (to.name === "not-found") {
		return next();
	}

	if (authRequired) {
		if (await checkAuth()) {
			return next();
		} else {
			return next("/sign-in");
		}
	}

	return next();
});

export default router;
