<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 7.75C9.40326 7.75 8.83097 7.98705 8.40901 8.40901C7.98705 8.83097 7.75 9.40326 7.75 10C7.75 10.5967 7.98705 11.169 8.40901 11.591C8.83097 12.0129 9.40326 12.25 10 12.25C10.5967 12.25 11.169 12.0129 11.591 11.591C12.0129 11.169 12.25 10.5967 12.25 10C12.25 9.40326 12.0129 8.83097 11.591 8.40901C11.169 7.98705 10.5967 7.75 10 7.75Z"
			fill="#FAFAFA"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10 3.5C7.382 3.5 5.028 4.551 3.332 5.853C2.482 6.505 1.785 7.229 1.296 7.933C0.816 8.625 0.5 9.351 0.5 10C0.5 10.649 0.817 11.375 1.296 12.066C1.786 12.771 2.482 13.495 3.332 14.146C5.028 15.45 7.382 16.5 10 16.5C12.618 16.5 14.972 15.449 16.668 14.147C17.518 13.495 18.215 12.771 18.703 12.067C19.183 11.375 19.5 10.649 19.5 10C19.5 9.351 19.183 8.625 18.703 7.934C18.215 7.229 17.518 6.505 16.668 5.854C14.972 4.55 12.618 3.5 10 3.5ZM6.25 10C6.25 9.00544 6.64509 8.05161 7.34835 7.34835C8.05161 6.64509 9.00544 6.25 10 6.25C10.9946 6.25 11.9484 6.64509 12.6517 7.34835C13.3549 8.05161 13.75 9.00544 13.75 10C13.75 10.9946 13.3549 11.9484 12.6517 12.6517C11.9484 13.3549 10.9946 13.75 10 13.75C9.00544 13.75 8.05161 13.3549 7.34835 12.6517C6.64509 11.9484 6.25 10.9946 6.25 10Z"
			fill="#FAFAFA"
		/>
	</svg>
</template>
