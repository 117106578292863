<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M24.9441 8.94265C25.4648 8.42195 25.4648 7.57773 24.9441 7.05703C24.4234 6.53633 23.5792 6.53633 23.0585 7.05703L16.0013 14.1142L8.94411 7.05703C8.42341 6.53633 7.57919 6.53633 7.05849 7.05703C6.53779 7.57773 6.53779 8.42195 7.05849 8.94265L14.1157 15.9998L7.05849 23.057C6.53779 23.5777 6.53779 24.4219 7.05849 24.9426C7.57919 25.4633 8.42341 25.4633 8.94411 24.9426L16.0013 17.8855L23.0585 24.9426C23.5792 25.4633 24.4234 25.4633 24.9441 24.9426C25.4648 24.4219 25.4648 23.5777 24.9441 23.057L17.8869 15.9998L24.9441 8.94265Z"
			fill="#FAFAFA"
		/>
	</svg>
</template>
