<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_588_1432)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.23228 5.48387C4.23229 5.48385 4.2323 5.48384 4.23232 5.48383L7.7199 8.97142C7.71989 8.97143 7.71989 8.97145 7.71988 8.97147L4.23228 5.48387ZM3.03493 6.64354C2.28703 7.47208 1.58899 8.454 0.941763 9.58683C0.869854 9.71272 0.832031 9.85519 0.832031 10.0002C0.832031 10.1451 0.869854 10.2876 0.941763 10.4135C3.30093 14.5427 6.3351 16.6668 9.99843 16.6668L10.2593 16.6635C11.0904 16.6403 11.8887 16.506 12.6535 16.2621L3.03493 6.64354ZM14.2919 15.5434C14.2919 15.5434 14.2919 15.5434 14.2919 15.5435L11.0271 12.2787C11.0271 12.2787 11.0272 12.2787 11.0272 12.2787L14.2919 15.5434ZM16.8939 13.4315C17.6675 12.5876 18.3883 11.581 19.0551 10.4143L19.0684 10.3893L19.0984 10.3268L19.1309 10.2368C19.1399 10.2067 19.1471 10.1761 19.1526 10.1452L19.1643 10.0527V9.961L19.1551 9.87766L19.1434 9.80933L19.1318 9.7635L19.1068 9.69433L19.0693 9.611L18.8859 9.29516C16.5509 5.35766 13.5734 3.3335 9.99843 3.3335C9.03363 3.3335 8.11247 3.48083 7.23578 3.77333L11.3771 7.91469C11.5161 8.00658 11.6466 8.11281 11.7662 8.2324C11.8858 8.35198 11.992 8.48244 12.0839 8.62145L16.8939 13.4315Z"
				fill="#FAFAFA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.42246 1.49404C1.7479 1.1686 2.27554 1.1686 2.60097 1.49404L19.1001 17.9932C19.4256 18.3186 19.4256 18.8463 19.1001 19.1717C18.7747 19.4971 18.2471 19.4971 17.9216 19.1717L1.42246 2.67255C1.09703 2.34712 1.09703 1.81948 1.42246 1.49404Z"
				fill="#FAFAFA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_588_1432">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
