import { createI18n } from "vue-i18n";

// import translations
import fr from "@/locales/fr.json";
import en from "@/locales/en.json";

const getCurrentBrowserLocale = () => {
	const lang = localStorage.getItem("locale") || navigator.language;
	localStorage.setItem("locale", lang);
	return lang.split("-")[0];
};

const i18n = createI18n({
	// Set locale
	locale: getCurrentBrowserLocale() || "en",

	// Set fallback locale
	fallbackLocale: "en",

	// Must be set to 'false', to use Composition API
	legacy: false,

	// Refer a global scope Composer instance of i18n
	globalInjection: true,

	// Set locale messages
	messages: { fr, en },
});

export default i18n;