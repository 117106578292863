import requestProxy from "@/utils/requestProxy";

const getBusinessSession = async (): Promise<any> => {
	try {
		const res = await requestProxy.get("/auth/businessSession", {
			params: { my_user_id: localStorage.getItem("userId") },
		});
		const businessSession = res.data;

		businessSession.name = res.data?.name || "";
		businessSession.id_partner = res.data?.id_partner || 0;
		businessSession.permission_group = res.data?.permission_group || 0;
		businessSession.admin = res.data?.admin || false;
		businessSession.emailvalidated = res.data?.emailvalidated || false;
		businessSession.rekium = new Intl.NumberFormat("en-US").format(
			res.data?.rekium
		);
		businessSession.subscription = res.data.subscription || "";
		businessSession.sub_end_date = res.data.sub_end_date || "";

		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postBindPlayerToPartnerCreation = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/partner/bindPlayerToPartnerCreation",
			data
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postAddOnboardingInfo = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/partner/addOnboardingInfo?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner"),
			data
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getMyPartner = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/partner/myPartner?my_user_id=" + localStorage.getItem("userId")
		);
		const data = res.data;

		data.firstName = res.data?.contact_full_name?.split(" ")[0] || "";
		data.lastName = res.data?.contact_full_name?.split(" ")[1] || "";

		data.iban = res.data?.RIB?.split(";")[0] || "";
		data.bic = res.data?.RIB?.split(";")[1] || "";

		data.baseAddress = res.data?.address?.split(", ")[0] || "";
		data.postcode = res.data?.address?.split(", ")[1] || "";
		data.city = res.data?.address?.split(", ")[2] || "";
		data.country = res.data?.address?.split(", ")[3] || "";

		return data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getBusinessHomeStats = async (): Promise<any> => {
	try {
		const res = await requestProxy.get("/stats/businessHomeStats", {
			params: { id_partner: localStorage.getItem("idPartner") },
		});
		const businessHomeStats = res.data;

		businessHomeStats.active_map_count = res.data?.active_map_count || 0;
		businessHomeStats.giveaway_count = res.data?.giveaway_count || 0;
		businessHomeStats.partner_news_count =
			res.data?.partner_news_count || 0;
		businessHomeStats.shop_irl_count = res.data?.shop_irl_count || 0;

		return businessHomeStats;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postPartnerImage = async (data: FormData): Promise<any> => {
	try {
		const res = await requestProxy.post("/upload/partnerImage", data);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

const postCreatePartnerAccount = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/partner/createPartnerAccount",
			data
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postConfirmEmail = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post("/auth/confirm_email", data);
		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postPartnerAccountUpdate = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/partner/accountUpdate?my_user_id=" +
				localStorage.getItem("userId"),
			data
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postPartnerProfilUpdate = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/partner/profilUpdate?id_partner=" +
				localStorage.getItem("idPartner"),
			data
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postCheckPaswword = async (
	email: string,
	password: string
): Promise<any> => {
	try {
		const res = await requestProxy.post("/auth/businessLogin", {
			email,
			password,
		});

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postResetPassword = async (
	token: string,
	password: string
): Promise<any> => {
	try {
		const res = await requestProxy.post("/auth/reset_password", {
			token: token,
			password: password,
		});

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postForgotPassword = async (
	email: string,
	type: number
): Promise<any> => {
	try {
		const res = await requestProxy.post("/auth/forgot_password", {
			email: email,
			type: type,
		});

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const deleteImage = async (url: string): Promise<any> => {
	try {
		const res = await requestProxy.delete(
			"/upload/deleteImage?imageUrl=" + url
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getAnalyticsOverview = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/analytics/partnerOverview?id_partner=" +
				localStorage.getItem("idPartner")
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getBuildingsAnalytics = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/analytics/buildingsAnalytics?id_partner=" +
				localStorage.getItem("idPartner")
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getSingleBuildingAnalytics = async (
	id_map: number,
	date_start: string,
	date_end: string
): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/analytics/singleBuildingAnalytic?&id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_map=" +
				id_map +
				"&date_start=" +
				date_start +
				"&date_end=" +
				date_end
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getSingleShopItemAnalytics = async (
	idShopIRL: string,
	date_start: string,
	date_end: string
): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/analytics/singleShopItemAnalytic?&id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_shop_irl=" +
				idShopIRL +
				"&date_start=" +
				date_start +
				"&date_end=" +
				date_end
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getSingleContestAnalytics = async (id_giveaway: string): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/stats/statsGiveaway?&id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_giveaway=" +
				id_giveaway
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getAdsAnalytics = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/analytics/partnersAdsAnalytics?id_partner=" +
				localStorage.getItem("idPartner")
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getSingleAdAnalytics = async (id_ads: number): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/analytics/singleAdAnalytic?&id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_ads=" +
				id_ads
		);
		return res.data;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postChangeEmail = async (
	email: string,
	password: string
): Promise<any> => {
	try {
		const res = await requestProxy.post("/auth/change_email", {
			my_user_id: localStorage.getItem("userId"),
			new_email: email,
			password: password,
		});

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postChangePassword = async (
	password: string,
	newPassword: string
): Promise<any> => {
	try {
		const res = await requestProxy.post("/auth/change_password", {
			my_user_id: localStorage.getItem("userId"),
			oldpassword: password,
			newpassword: newPassword,
		});

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postAddMap = async (data: any): Promise<any> => {
	try {
		data.my_user_id = localStorage.getItem("userId");
		data.id_partner = localStorage.getItem("idPartner");

		const res = await requestProxy.post("/map/addMap", data);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postModifyMap = async (id_map: string, data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/map/modifyMap?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_map=" +
				id_map,
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getPartnersMap = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/map/getPartnersMap?id_partner=" +
				localStorage.getItem("idPartner")
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getOneMap = async (id_map: string): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/map/getOne?id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_map=" +
				id_map
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getPartnersShop = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/shop/getPartners?my_user_id=" + localStorage.getItem("userId")
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postAddShop = async (data: any): Promise<any> => {
	data.id_partner = localStorage.getItem("idPartner");

	try {
		const res = await requestProxy.post(
			"/shop/addShop?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner"),
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postModifyShop = async (idShopIRL: string, data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/shop/modifyShop?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner") +
				"&idShopIRL=" +
				idShopIRL,
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getCheckOutSession = async (product: string): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/business/getCheckOutSession?my_user_id=" +
				localStorage.getItem("userId") +
				"&product=" +
				product
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getArticles = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/partner/getAsBusinessPartnersNews?id_partner=" +
				localStorage.getItem("idPartner")
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postAddArticle = async (data: any): Promise<any> => {
	data.id_partner = localStorage.getItem("idPartner");

	try {
		const res = await requestProxy.post(
			"/partner/addPartnerNews?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner"),
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postModifyArticle = async (id_news: string, data: any): Promise<any> => {
	data.id_partner = localStorage.getItem("idPartner");

	try {
		const res = await requestProxy.post(
			"/partner/modifyPartnerNews?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_partner_news=" +
				id_news,
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getContests = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/giveaway/getPartners?id_partner=" +
				localStorage.getItem("idPartner")
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postAddContest = async (data: any): Promise<any> => {
	data.id_partner = localStorage.getItem("idPartner");

	try {
		const res = await requestProxy.post(
			"/giveaway/addGiv?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner"),
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postAddRewards = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/giveaway/addRewards?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner"),
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postWinnerSendReward = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post(
			"/giveaway/winnerSendReward?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner"),
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getWinners = async (id_giveaway: string): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/giveaway/winnersAddress?my_user_id=" +
				localStorage.getItem("userId") +
				"&id_partner=" +
				localStorage.getItem("idPartner") +
				"&id_giveaway=" +
				id_giveaway
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getAds = async (): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/other/getPartnerAds?id_partner=" +
				localStorage.getItem("idPartner") +
				"&my_user_id=" +
				localStorage.getItem("userId")
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postCreateAdd = async (data: any): Promise<any> => {
	data.id_partner = localStorage.getItem("idPartner");

	try {
		const res = await requestProxy.post(
			"/other/partnerCreateAd?id_partner=" +
				localStorage.getItem("idPartner") +
				"&my_user_id=" +
				localStorage.getItem("userId"),
			data
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const getPrice = async (product: string): Promise<any> => {
	try {
		const res = await requestProxy.get(
			"/business/getPrice?product=" + product
		);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postCheckEmail = async (email: string): Promise<any> => {
	try {
		const res = await requestProxy.post("/auth/check/email", {
			email,
		});

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

const postContactMail = async (data: any): Promise<any> => {
	try {
		const res = await requestProxy.post("/other/contactMail", data);

		return res;
	} catch (err: any) {
		return err.response || { error: "Unknow error" };
	}
};

export default {
	getBusinessSession,
	postBindPlayerToPartnerCreation,
	postAddOnboardingInfo,
	getBusinessHomeStats,
	postPartnerImage,
	postCreatePartnerAccount,
	postConfirmEmail,
	postPartnerAccountUpdate,
	getMyPartner,
	postCheckPaswword,
	postPartnerProfilUpdate,
	deleteImage,
	getAnalyticsOverview,
	getBuildingsAnalytics,
	getSingleBuildingAnalytics,
	getSingleContestAnalytics,
	getSingleShopItemAnalytics,
	getSingleAdAnalytics,
	getAdsAnalytics,
	postChangeEmail,
	postChangePassword,
	postResetPassword,
	postForgotPassword,
	postAddMap,
	postModifyMap,
	getPartnersMap,
	getOneMap,
	getPartnersShop,
	postAddShop,
	postModifyShop,
	getCheckOutSession,
	getArticles,
	postAddArticle,
	postModifyArticle,
	getContests,
	postAddContest,
	postAddRewards,
	getWinners,
	postWinnerSendReward,
	getAds,
	postCreateAdd,
	getPrice,
	postCheckEmail,
	postContactMail,
};
