import axios from "axios";
import router from "../router/index";
import Vue from "vue";
import checkAuth from "./checkAuth";

const requestProxy = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 3000,
	withCredentials: true,
});

export default requestProxy;
