// stores/counter.js
import { defineStore } from "pinia";

export const useProfilData = defineStore("profilData", {
	state: () => {
		return {
			rekiums: 0,
			subscription: "Free",
		};
	},
	getters: {
		getRekiums: (state) => state.rekiums,
		getSubscription: (state) => state.subscription,
	},
});
