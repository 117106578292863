import requestProxy from "./requestProxy";
import router from "../router/index";

const checkAuth = async () => {
	try {
		const authExpiration = localStorage.getItem("authExpiration");
		if (!authExpiration) {
			return false;
		}

		const currentTime = new Date().getTime();
		if (currentTime > new Date(Number(authExpiration)).getTime()) {
			const response = await requestProxy.post("/auth/refreshBusiness");
			//console.log("refreshBusiness response", response);

			if (response.status === 200) {
				localStorage.setItem(
					"authExpiration",
					(new Date().getTime() + 15 * 60 * 1000).toString()
				);
			} else {
				return false;
			}
		}

		return true;
	} catch (error) {
		return false;
	}
};

export default checkAuth;
