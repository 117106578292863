import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "@/assets/destyle.css";
import "@/assets/global.less";

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(Vue3Toasity, {
	position: "top-center",
	autoClose: 3000,
	closeButton: false,
	theme: "dark",
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: false,
	hideProgressBar: true,
	transition: "slide",
	clearOnUrlChange: false,
} as ToastContainerOptions);

await router.isReady();
app.mount("#app");
